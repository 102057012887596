<template>
  <v-app theme="FrontTheme">
    <TheHeader />
    <v-main>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useAuthStore } from '~~/stores/auth';

const authStore = useAuthStore();
await authStore.checkAuth();
useHead({
  titleTemplate: (title) => (title ? `${title} - MineClub` : 'MineClub'),
  link: [
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
  ],
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: 'MineClub - En dansk Minecraft server!',
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: 'MineClub - En dansk Minecraft server!',
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: 'MineClub - En dansk Minecraft server!',
    },
    {
      hid: 'og:type',
      property: 'og:type',
      content: 'website',
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: '/og-image.png',
    },
  ],
  htmlAttrs: {
    lang: 'da-DK',
  },
});
</script>

<style scoped>
a {
  color: inherit;
  text-decoration: none;
}
</style>
